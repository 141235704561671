import React, {useEffect, useState} from 'react';
import {Space, Table, Tag} from 'antd';
import {getPopularRank, getTotalTasks} from "../../config/api";
import {useRequest} from "ahooks";

const PopularRankTable = () => {
    const [rank, setRank] = useState([])
    const [total, setTotal] = useState(0)
    const {runAsync, loading} = useRequest(getPopularRank, {manual: true});
    useEffect(() => {
        runAsync().then(r => {
            setRank(r)
        })
        getTotalTasks().then(r => setTotal(r.totalTaskCount))
    }, [])

    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
            align: 'center',
            width: 80,
            render: (text, record, index) => <div>{index + 1}</div>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            width: 120,
            render: (text) => {
                let color = text === "designer" ? "green" : "gold"
                return <div style={{textTransform: "capitalize"}}><Tag color={color}>{text}</Tag></div>
            }
        },
        {
            title: 'Option',
            dataIndex: 'option',
            key: 'option',
            align: 'center',
        },
        {
            title: 'Times',
            key: 'percent',
            dataIndex: 'percent',
            align: 'center',
            width: 100,
            render: (text) => <div>{text}</div>
        },
        {
            title: 'Percent',
            key: 'percent',
            dataIndex: 'percent',
            align: 'center',
            width: 100,
            render: (text) => <div>{((text / total) * 100).toFixed(0)}%</div>
        }
    ];

    return <div className="card" style={{flex: 1}}>
        <div className="title-graph">Popular</div>
        <Table style={{textAlign: "center"}} columns={columns} dataSource={rank} pagination={{pageSize: 5}} loading={loading} size="small"/>
    </div>;
}
export default PopularRankTable;