import {Button, Form, Input, Image, Upload, message} from "antd";
import {useEffect, useState} from "react";
import {DeleteOutlined, PictureOutlined} from "@ant-design/icons";
import {generate, getPopularRank, getPrompt, playgroundPromptUpdate} from "../../config/api";
import {useRequest} from "ahooks";

const Playground = () => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)

    const [beforeImage, setBeforeImage] = useState("https://10kv.oss-cn-shanghai.aliyuncs.com/image/fc50b50f-c7c5-41bd-9e73-9fa239f39dad.jpg");
    const [afterImage, setAfterImage] = useState("");

    const {runAsync: doGenerate, loading: generateLoading} = useRequest(generate, {manual: true});
    const {runAsync: doGetPrompt, loading: getPromptLoading} = useRequest(getPrompt, {manual: true});

    const submit = (e) => {
        setAfterImage("")
        setLoading(true)
        setCount(0)
        let a = 0
        let interval = setInterval(() => {
            setCount(a)
            a += 10
        }, 10)
        doGenerate({
            imageUrl: beforeImage,
            ...e
        })
            .then(r => {
                if (r === "Timeout") {
                    message.error("Timeout (120s)").then()
                } else if (r === undefined) {
                    message.error("Error").then()
                } else {
                    setAfterImage(r)
                }
            })
            .finally(() => {
                setLoading(false)
                clearInterval(interval)
            })

    }

    const props = {
        name: 'file',
        // fileList: [],
        action: "https://aiviz.cn/api/aiviz/playground/upload",
        onChange: (e) => {
            if (e?.file?.response !== undefined) {
                setBeforeImage(e.file.response)
            } else {
                console.log(e)
            }
        }
    };

    useEffect(() => {
        if (localStorage.getItem("basicPrompt") === null) {
            doGetPrompt().then(r => {
                form.setFieldValue("personalPrompt", "")
                form.setFieldValue("basicPrompt", r.basicPrompt)
                form.setFieldValue("negativePrompt", r.negativePrompt)
            })
        } else {
            form.setFieldValue("personalPrompt", localStorage.getItem("personalPrompt"))
            form.setFieldValue("basicPrompt", localStorage.getItem("basicPrompt"))
            form.setFieldValue("negativePrompt", localStorage.getItem("negativePrompt"))
        }
    }, [])

    return (
        <>
            <div className="card">

                <div style={{display: "flex", gap: 20, flexWrap: "wrap"}}>
                    <div style={{flex: 1, minWidth: 350,  display: "flex", flexDirection: "column", gap: 10}}>
                        Input
                        {beforeImage === "" ?
                            <Upload.Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <PictureOutlined style={{color: "#aaa"}}/>
                                </p>
                                <p className="ant-upload-text" style={{color: "#aaa"}}>Click or drag file to this area
                                    to upload</p>
                                <p className="ant-upload-hint" style={{color: "#aaa"}}>
                                    Support for a single or bulk upload. Strictly prohibited from uploading company data
                                    or other
                                    banned files.
                                </p>
                            </Upload.Dragger> :
                            <div className="user-image" onClick={() => setBeforeImage("")}>
                                <img src={beforeImage} alt="" style={{width: "100%", height: "auto"}}/>
                            </div>
                        }
                    </div>
                    <div style={{flex:1, minWidth: 350, display: "flex", flexDirection: "column", gap: 10}}>
                        <div>Output</div>
                        <div style={{flex: 1}}>
                            {
                                afterImage === "" ?
                                    <div style={{height: "100%", backgroundColor: "#f8f8f8", borderRadius: 10, border: "1px dashed #d9d9d9", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        {count === 0 ? "Waiting To Start" : count / 1000 + "s"}
                                    </div> :
                                    <img src={afterImage} alt="" style={{width: "100%", height: "auto"}}/>
                            }

                        </div>
                    </div>
                </div>
                <div>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={submit}
                    >
                        <Form.Item name="personalPrompt" label="Personal Prompt">
                            <Input.TextArea autoSize/>
                        </Form.Item>
                        <Form.Item name="basicPrompt" label="Basic Prompt">
                            <Input.TextArea autoSize/>
                        </Form.Item>
                        <Form.Item name="negativePrompt" label="Negative Prompt">
                            <Input.TextArea autoSize/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" block htmlType="submit" loading={loading}>Generate</Button>
                        </Form.Item>
                        <Form.Item label="Action">
                            <div style={{display: "flex", gap: 20, flexWrap: "wrap"}}>
                                <Button style={{flex: 1}} onClick={() => form.setFieldValue("personalPrompt", "(Cyberpunk style, neon:1.4), (Office: 1.4), (Daytime, natural light:1.4)")}>Random Personal Prompt</Button>
                                <Button style={{flex: 1}} onClick={() => {
                                    doGetPrompt().then(r => {
                                        form.setFieldValue("personalPrompt", "")
                                        form.setFieldValue("basicPrompt", r.basicPrompt)
                                        form.setFieldValue("negativePrompt", r.negativePrompt)}
                                    )
                                }} loading={getPromptLoading}>Load Online Prompt</Button>
                                <Button style={{flex: 1}} type="primary" ghost onClick={() => {
                                    message.success("Local temporary storage successful").then()
                                    let prompt = form.getFieldsValue()
                                    localStorage.setItem("personalPrompt", prompt.personalPrompt)
                                    localStorage.setItem("basicPrompt", prompt.basicPrompt)
                                    localStorage.setItem("negativePrompt", prompt.negativePrompt)
                                }}>Local Temporary Storage</Button>
                                <Button style={{flex: 2}} type="primary" danger
                                    onClick={() => {
                                        let prompt = form.getFieldsValue()
                                        console.log(form.getFieldsValue())
                                        playgroundPromptUpdate({
                                            object: {
                                                basicPrompt: prompt.basicPrompt,
                                                negativePrompt: prompt.negativePrompt
                                            }
                                        }).then(r => {
                                            console.log(r)
                                            message.success("Update Success").then()
                                            localStorage.setItem("personalPrompt", form.getFieldsValue().personalPrompt)
                                            localStorage.setItem("basicPrompt", r.basicPrompt)
                                            localStorage.setItem("negativePrompt", r.negativePrompt)
                                        })
                                    }}
                                >Deploy To Online</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default Playground