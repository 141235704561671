import React from "react";
import DigitalCard from "./digitalCard";
import UsageDetailGraph from "./usageDetailGraph";
import PopularRankTable from "./popularRankTable";
import Demo from "./demo";

const Home = () => {
    return (
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <DigitalCard/>
            <UsageDetailGraph/>
            <div style={{display: "flex", flexWrap: "wrap", gap: 20}}>
                {/*<Demo/>*/}
                <PopularRankTable/>
            </div>
        </div>
    );
}

export default Home;
