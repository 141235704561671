import React, {useEffect, useState} from 'react';
import {Table, Switch, Tag} from 'antd';
import {useRequest} from "ahooks";
import {changeTaskState, getTaskList} from "../../config/api";

const methods = {
    method1: getTaskList,
    method2: changeTaskState,
    // 其他方法
};
const TaskDetailTable = () => {
    const [taskList, setTaskList] = useState([])
    const [pageSize, setPageSize] = useState(10);
    const [handle, setHandle] = useState("")
    const [total, setTotal] = useState(0);
    const {runAsync, loading} = useRequest(getTaskList, {manual: true})
    const {runAsync: changeState} = useRequest(changeTaskState, {manual: true})
    const handleTaskList = (r) => {
        return r?.map(task => {
            return {key: task.id, ...task, timeCost: task.timeCost / 1000}
        })
    }
    useEffect(() => {
        runAsync({current: 0, pageSize: 10}).then(r => {
            setTaskList(handleTaskList(r?.list));
            setTotal(r?.total);
        }
        )
    }, [])
    const changePageMessage = (page, newPageSize) => {
        if (pageSize !== newPageSize) {
            if (newPageSize <= pageSize) {
                setTaskList(taskList.slice(0, newPageSize))
                setPageSize(newPageSize);
                return
            } else {
                setTotal(0)
                setTaskList([])
            }
        }
        setPageSize(newPageSize);
        runAsync({current: page - 1, pageSize: newPageSize}).then(r => {
            setTaskList(handleTaskList(r.list))
            setTotal(r.total);
        })
    };
    const onChange = (record) => {
        if (handle !== "") return
        const {id, visible} = record
        setHandle(id)
        let index = taskList.indexOf(record)
        let newTaskList = taskList
        newTaskList[index].visible = !visible
        setTaskList(newTaskList)
        changeState({id, type: !visible ? "show" : "hide"}).then(r => {
            if (!r) {
                newTaskList[index].visible = visible
                setTaskList(newTaskList)
            }
            setHandle("")
        })

    }
    const columns = [
        {
            title: 'UserId',
            dataIndex: 'userId',
            key: 'userId',
            width: 200
        },
        {
            title: 'TimeCost',
            dataIndex: 'timeCost',
            key: 'timeCost',
            width: 90
        }, {
            title: 'DateTime',
            dataIndex: 'dateTime',
            key: 'dateTime',
            width: 160
        },
        {
            title: 'Visible',
            dataIndex: 'visible',
            key: 'visible',
            width: 60,
            render: (_, record) => (
                <Switch size="small" checked={record.visible}
                    onChange={() => onChange(record)}
                    loading={record.id === handle}/>),
        },
    ];
    return <div className="card">
        <Table
            columns={columns}
            scroll={{x: 400}}
            pagination={{
                onChange: changePageMessage,
                // position: ["bottomCenter"],
                pageSize: pageSize,
                total: total
            }}
            expandable={{
                expandedRowRender: (record) => (
                    <>
                        <div style={{display: "flex", gap: 20, width: "100%"}}>
                            <div style={{flex: "1"}}>
                                <div style={{fontWeight: 800, fontSize: 16}}>Input</div>
                                <img style={{width: "100%", marginTop: 5}} src={record.request} alt="befor image"/>
                            </div>
                            <div style={{flex: "1"}}>
                                <div style={{fontWeight: 800, fontSize: 16}}>Output</div>
                                <img style={{width: "100%", marginTop: 5}} src={record.response} alt="after image"/>
                            </div>
                        </div>
                        <div style={{marginTop: 20}}><strong>Prompt: </strong>{record.text}</div>
                        <div style={{marginTop: 20}}><strong>State: </strong>{record.publish ? (
                            <Tag color="green">Published</Tag>) : (<Tag>Unpublished</Tag>)}</div>
                    </>
                ),
                rowExpandable: (record) => true,
            }}
            dataSource={taskList}
            size="small"
            tableLayout="fixed"
            loading={loading}
        />
    </div>
}

export default TaskDetailTable;