import {useEffect, useState} from "react";
import {getUpdateLog, optionList, updateUpdateLog} from "../../config/api";
import {Button, Input, message, Tag} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import index from "../../store";
import dayjs from "dayjs";

const UpdateLogs = () => {
    const [updateLogData, setUpdateLogData] = useState([])
    const [reLoad, setReLoad] = useState([])
    const [active, setActive] = useState({})
    useEffect(() => {
        getUpdateLog().then(r => {
            setUpdateLogData(r)
            setActive(r[0] ? r[0] : {})
        })
    }, []);
    const addVersion = () => {
        const newData = updateLogData
        const v = [...updateLogData[0].version]
        const last = v.pop()
        v.push(parseInt(last) + 1)
        newData.unshift({version: v.join(""),date: dayjs().format("YYYY-MM-DD"),newList: [], updateList: [], debugList: []})
        setUpdateLogData(newData)
        setActive(newData[0])
    }
    const addList = (item) => {
        const newData = updateLogData
        let index = updateLogData.indexOf(active)
        newData[index][item].push("")
        setUpdateLogData(newData)
        setReLoad([])
    }
    const onChange1 = (item) => (e) => {
        let newData = updateLogData
        let index = updateLogData.indexOf(item)
        newData[index].version = e.target.value
        setUpdateLogData(newData)
        setReLoad([])
    }

    const onChange2 = () => (e) => {
        let newData = updateLogData
        let index = updateLogData.indexOf(active)
        newData[index].date = e.target.value
        setUpdateLogData(newData)
        setReLoad([])
    }
    const onChange3 = (item,index2) => (e) => {
        let newData = updateLogData
        let index = updateLogData.indexOf(active)
        newData[index][item][index2] = e.target.value
        setUpdateLogData(newData)
        setReLoad([])
    }
    const updateOption = () => {
        updateUpdateLog({object: {list: updateLogData}}).then(r => {
            message.success("更改成功").then()
            setUpdateLogData(r)
        })
    }
    const listDelete = (item , index) => {
        const newData = updateLogData
        const index1 = newData.indexOf(active)
        newData[index1][item].splice(index,1)
        setUpdateLogData(newData)
        setReLoad([])
    }

    return (
        <>
            <div style={{display: "flex", gap: 20, flexWrap: "wrap"}}>
                <div className="card" style={{maxWidth: 250, flex: 1}}>
                    {
                        updateLogData?.map((item, index) => {
                            return (<Input className={"div-button " + (item.version === active.version ? "div-button-active" : "")}
                                key={index}
                                onChange={onChange1(item)}
                                onClick={() => setActive(item)}  value={item.version}/>
                            )
                        })
                    }
                    <div style={{padding: 5, borderRadius: 10, border: "1px dashed #aaa", textAlign: "center", cursor: "pointer"}} onClick={addVersion}>Add
                    </div>
                </div>
                <div className="card" style={{flex: 1}}>
                    <div>{
                        active.version !== "" ? <Tag color="blue">{active.version}</Tag> : <Tag color="blue">请输入版本号</Tag>
                    }</div>
                    <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                        <div style={{display: "flex", flexDirection: "column", gap: 30}}>
                            <div style={{display: "flex", gap: 20}}>
                                <div><Input value="date" disabled/></div>
                                <div style={{display: "flex", flexDirection: "column", flex: 1, gap: 20}}>
                                    <div style={{flex: 1}}><Input value={active.date} onChange={onChange2(active)}/></div>
                                </div>
                            </div>
                            {
                                ["newList", "updateList", "debugList"].map((item2, index2) => {
                                    return (
                                        <div style={{display: "flex", gap: 20}} key={index2}>
                                            <div><Input value={item2} disabled onChange={onChange1()}/></div>
                                            <div style={{display: "flex", flexDirection: "column", gap: 20, flex: 1}}>
                                                {
                                                    active?.[item2]?.map((item3, index3) => {
                                                        return (
                                                            <div style={{flex: 1, display: "flex",flexDirection: "column", gap: 20}} key={index3}>
                                                                <div style={{display: "flex", gap: 5}}>
                                                                    <div style={{flex: 1}}><Input value={item3}
                                                                        onChange={onChange3(item2,index3)}/>
                                                                    </div>
                                                                    <div><DeleteOutlined onClick={() => listDelete(item2,index3)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div style={{
                                                    padding: 5,
                                                    borderRadius: 10,
                                                    border: "1px dashed #aaa",
                                                    textAlign: "center",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() =>addList(item2)}
                                                >Add
                                                </div>
                                            </div>
                                        </div>)

                                })
                            }
                        </div>
                    </div>
                </div>
                {/*底部按钮*/}
                <div style={{
                    position: "fixed",
                    bottom: 0,
                    backgroundColor: "#fff",
                    borderTop: " 1px solid #b0b0b0",
                    height: 80,
                    width: "100%",
                    maxWidth: 1516,
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: 20
                }}>
                    <Button style={{maxWidth: 300}} onClick={() => {
                        getUpdateLog().then(r => {
                            setUpdateLogData(r)
                            setActive(r[0] ? r[0] : {})
                            message.success("获取成功")
                        })
                    }}>Load Online Prompt</Button>
                    <Button style={{maxWidth: 300}} onClick={updateOption} type="primary" danger>Deploy To
                        Online</Button>
                </div>
            </div>
        </>
    )

}
export default UpdateLogs