import React from "react";

import Layout from "../common/layout";
import Result404 from "../common/result/404"
import Login from "../common/user/login2";
import Home from "../pages/home";
import Yang from "../pages/yang";

import Header from "../common/layout/component/header";


import {
    UnorderedListOutlined,
    CloudOutlined,
    DashboardOutlined,
    ExperimentOutlined,
    FormOutlined,
    FundOutlined, HighlightOutlined, StarOutlined, ToolOutlined
} from "@ant-design/icons";
import {Watermark} from "antd";
import Playground from "../pages/playground";
import TaskList from "../pages/taskList";
import Feedback from "../pages/feedback";
import Prompt from "../pages/prompt";
import UpdateLog from "../pages/updateLog";
import UpdateLogs from "../pages/updateLog";
import DataGraph from "../pages/dataGraph";

// 路由表
export const routes = [
    {
        path: "/",
        element: <Layout/>,
        children: [
            {
                index: true,
                element: <Home/>,
                icon: <DashboardOutlined/>,
                label: "Dashboard"
            },
            {
                path: "/taskList",
                element: <TaskList/>,
                icon: <UnorderedListOutlined/>,
                label: "TaskList"

            },
            {
                path: "/playground",
                element: <Playground/>,
                icon: <ExperimentOutlined/>,
                label: "Playground"

            },
            {
                path: "/feedback",
                element: <Feedback/>,
                icon: <StarOutlined />,
                label: "Feedback"

            },
            {
                path: "/prompt",
                element: <Prompt/>,
                icon: <HighlightOutlined />,
                label: "Prompt"

            },
            {
                path: "/updateLogs",
                element: <UpdateLogs/>,
                icon: <ToolOutlined />,
                label: "UpdateLogs"

            }, {
                path: "/dataGraph",
                element: <DataGraph/>,
                icon: <ToolOutlined />,
                label: "DataGraph"

            },
            {
                path: "*",
                element: <Result404/>,
            }

        ]
    },
    {
        path: "/freight",
        element: <Yang/>,
    },
    {
        path: "/login",
        element: <Login/>,
    },
    {
        path: "*",
        element: <Result404/>,
    }
];
