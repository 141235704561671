import {Column} from '@ant-design/plots';
import {useEffect, useState} from "react";
import {getUsageDetail} from "../../config/api";
import {useRequest} from "ahooks";
import {Spin} from "antd";

const UsageDetailGraph = () => {
    const [usageDetail, setUsageDetail] = useState([])
    const [handleUsageDetail, setHandleUsageDetail] = useState([])
    const {runAsync, loading} = useRequest(getUsageDetail, {manual: true});
    const [col, setCol] = useState(24)
    useEffect(() => {
        const handleResize = () => {
            let gearArr = [4, 6, 8, 12, 24]
            // 24 12 8 6 4 2
            let gear = Math.floor((window.innerWidth - 350) / 170)
            if (gear > 4) {
                setCol(gearArr[4])
            } else if (gear < 0) {
                setCol(gearArr[0])
            } else setCol(gearArr[gear])
        }
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])
    useEffect(() => {
        runAsync().then(r => {
            if (r?.length !== 24) throw new Error("data formatting error")
            setUsageDetail(r)
        })
    }, [])
    useEffect(() => {
        let draw = () => {
            //Divide the array into col sections
            let data = []
            let colSum = 24 / col
            for (let i = 0; i < col; i++) {
                let taskCount = 0
                let averageTimeCost = 0
                let timeRange = (col === 24) ? i + '\'' : `${i * colSum}-${(i + 1) * colSum}`
                for (let j = 0; j < colSum;) {
                    taskCount += usageDetail[i * colSum + j]?.taskCount
                    averageTimeCost += usageDetail[i * colSum + j]?.averageTimeCost / colSum
                    j++;
                }
                data.push({taskCount, averageTimeCost, timeRange})
            }
            setHandleUsageDetail(data)
        }
        draw()

    }, [usageDetail, col])

    const config = {
        data: handleUsageDetail,
        xField: 'timeRange',
        yField: 'taskCount',
        tooltip: {
            shared: true,
            showMarkers: false,
            // Show three values
            customContent: function (x, data) {
                let timeRange = data[0]?.data.timeRange
                const changeTimeRange = (timeRange) => {
                    let timeArr = timeRange?.split("-")
                    return `${timeArr?.[0]}:00-${timeArr?.[1]}:00`
                }
                let handleTimeRange = timeRange?.includes('\'') ?
                    parseInt(timeRange) + ":00 - " + (parseInt(timeRange) + 1) + ":00" :
                    changeTimeRange(timeRange)

                return (
                    <div style={{
                        padding: "20px 10px",
                        fontSize: 16,
                        display: "flex",
                        flexDirection: "column",
                        gap: 10
                    }}>
                        {/*<div style={{display:"flex"}}>{data[0]?.data.timeRange}</div>*/}
                        <div style={{display: "flex"}}>
                            <div style={{fontWeight: 600, fontSize: 18, height: 20}}>
                                {handleTimeRange}
                            </div>
                        </div>
                        <div style={{display: "flex"}}>
                            <div style={{width: 110}}>TaskCount:</div>
                            {data[0]?.data.taskCount}</div>
                        <div style={{display: "flex"}}>
                            <div style={{width: 110}}>TimeCost:</div>
                            {(data[0]?.data.averageTimeCost / 1000).toFixed(3)}</div>
                    </div>

                )
            }
        },
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        // xAxis: {
        //     label: {
        //         autoHide: true,
        //         autoRotate: false,
        //     },
        // },
        // meta: {
        //     taskCount: {
        //         alias: '任务总数',
        //     },
        //     averageTimeCost: {
        //         alias: '平均耗时',
        //     },
        //     timeRange: {
        //         alias: '时间范围',
        //     },
        // },
    };

    return <div className="card">
        <div className="title-graph">Usage</div>
        <Spin spinning={loading}><Column {...config} loading={false}/></Spin>
    </div>;
};
export default UsageDetailGraph