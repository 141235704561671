import React, {useState} from 'react';
import {Dropdown, Typography, Popconfirm} from "antd";
import {LogoutOutlined, MenuUnfoldOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {doLogout} from "../../../config/request";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Content from "../../../config/content"
import {ProHelp, ProHelpDrawer, ProHelpModal, ProHelpPopover} from "@ant-design/pro-components";

const Help = () => {
    const [open, setOpen] = useState(false);

    const map = new Map();

    map.set('video', (item) => {
        return (
            <video
                key=""
                style={{
                    width: '100%',
                }}
                controls
                {...(item.children)}
            />
        );
    });

    map.set('list', (item) => {
        const listConfig = item.children;
        return (
            <div>
                <h3
                    style={{
                        margin: '8px 0',
                    }}
                >
                    {listConfig.title}
                </h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                    }}
                >
                    {listConfig.children.map((child, index) => {
                        return (
                            <div key={index}>
                                <Typography.Text>
                                    {child.href ? (
                                        <a href={child.href}>{child.title}</a>
                                    ) : (
                                        child.title
                                    )}
                                </Typography.Text>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    });

    return (
        <div>
            <ProHelp
                defaultSelectedKeys="1"
                dataSource={[
                    {
                        title: '常见问题',
                        key: 'a',
                        children: [
                            {
                                title: '如何开始操作数据授权？',
                                key: '1',
                                children: [
                                    {
                                        valueType: 'h1',
                                        children: '如何开始操作数据授权？',
                                    },
                                    {
                                        valueType: 'text',
                                        children: `需要进行数据合作的数据提供方（数据源）和数据需求方双方都需要先安装部署`,
                                    },
                                    {
                                        valueType: 'inlineLink',
                                        children: {
                                            href: 'https://www.alipay.com',
                                            children: '摩斯产品',
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children:
                                            '节点。并将各自的摩斯计算节点、子账号等的版本信息、业务需求、数据量级（几行几列）等信息同步给到摩斯产运负责人。',
                                    },
                                    {
                                        valueType: 'image',
                                        children: {
                                            src: 'https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*Jj_qRqbIRqkAAAAAAAAAAAAADml6AQ/original',
                                            style: {
                                                maxWidth: 600,
                                            },
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children: `需要进行数据合作的数据提供方（数据源）和数据需求方双方都需要先安装部署`,
                                    },
                                    {
                                        valueType: 'inlineLink',
                                        children: {
                                            href: 'https://www.alipay.com',
                                            children: '摩斯产品',
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children:
                                            '节点。并将各自的摩斯计算节点、子账号等的版本信息、业务需求、数据量级（几行几列）等信息同步给到摩斯产运负责人。',
                                    },
                                    {
                                        valueType: 'image',
                                        children: {
                                            src: 'https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*Jj_qRqbIRqkAAAAAAAAAAAAADml6AQ/original',
                                            style: {
                                                maxWidth: 600,
                                            },
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children: `需要进行数据合作的数据提供方（数据源）和数据需求方双方都需要先安装部署`,
                                    },
                                    {
                                        valueType: 'inlineLink',
                                        children: {
                                            href: 'https://www.alipay.com',
                                            children: '摩斯产品',
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children:
                                            '节点。并将各自的摩斯计算节点、子账号等的版本信息、业务需求、数据量级（几行几列）等信息同步给到摩斯产运负责人。',
                                    },
                                    {
                                        valueType: 'image',
                                        children: {
                                            src: 'https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*Jj_qRqbIRqkAAAAAAAAAAAAADml6AQ/original',
                                            style: {
                                                maxWidth: 600,
                                            },
                                        },
                                    },
                                    {
                                        valueType: 'h2',
                                        children: '相关问题',
                                    },
                                    {
                                        valueType: 'link',
                                        children: {
                                            href: 'www.alipay.com',
                                            children: '鹊凿平台DCI申领操作手册?',
                                        },
                                    },
                                    {
                                        valueType: 'link',
                                        children: {
                                            href: 'www.alipay.com',
                                            children: 'openAPI 注册工具?',
                                        },
                                    },

                                    {
                                        valueType: 'h2',
                                        children: '帮助视频',
                                    },
                                    {
                                        valueType: 'video',
                                        children: {
                                            src: 'https://mdn.alipayobjects.com/huamei_gcee1x/afts/file/A*oJOJRZwe00kAAAAAAAAAAAAADml6AQ',
                                        },
                                    },
                                ],
                            },
                            {
                                title: '证据包内包含哪些内容，如何下载证据包？',
                                key: '2',
                                children: [
                                    {
                                        valueType: 'h1',
                                        children: '证据包内包含哪些内容，如何下载证据包？',
                                    },
                                    {
                                        valueType: 'text',
                                        children: `需要进行数据合作的数据提供方（数据源）和数据需求方双方都需要先安装部署`,
                                    },
                                    {
                                        valueType: 'inlineLink',
                                        children: {
                                            href: 'https://www.alipay.com',
                                            children: '摩斯产品',
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children:
                                            '节点。并将各自的摩斯计算节点、子账号等的版本信息、业务需求、数据量级（几行几列）等信息同步给到摩斯产运负责人。',
                                    },
                                    {
                                        valueType: 'image',
                                        children: {
                                            src: 'https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*Jj_qRqbIRqkAAAAAAAAAAAAADml6AQ/original',
                                            style: {
                                                maxWidth: 600,
                                            },
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children: `需要进行数据合作的数据提供方（数据源）和数据需求方双方都需要先安装部署`,
                                    },
                                    {
                                        valueType: 'inlineLink',
                                        children: {
                                            href: 'https://www.alipay.com',
                                            children: '摩斯产品',
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children:
                                            '节点。并将各自的摩斯计算节点、子账号等的版本信息、业务需求、数据量级（几行几列）等信息同步给到摩斯产运负责人。',
                                    },
                                    {
                                        valueType: 'image',
                                        children: {
                                            src: 'https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*Jj_qRqbIRqkAAAAAAAAAAAAADml6AQ/original',
                                            style: {
                                                maxWidth: 600,
                                            },
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children: `需要进行数据合作的数据提供方（数据源）和数据需求方双方都需要先安装部署`,
                                    },
                                    {
                                        valueType: 'inlineLink',
                                        children: {
                                            href: 'https://www.alipay.com',
                                            children: '摩斯产品',
                                        },
                                    },
                                    {
                                        valueType: 'text',
                                        children:
                                            '节点。并将各自的摩斯计算节点、子账号等的版本信息、业务需求、数据量级（几行几列）等信息同步给到摩斯产运负责人。',
                                    },
                                    // @ts-expect-error
                                    {
                                        children:
                                            '节点。并将各自的摩斯计算节点、子账号等的版本信息、业务需求、数据量级（几行几列）等信息同步给到摩斯产运负责人。',
                                    },
                                    {
                                        valueType: 'image',
                                        children: {
                                            src: 'https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*Jj_qRqbIRqkAAAAAAAAAAAAADml6AQ/original',
                                            style: {
                                                maxWidth: 600,
                                            },
                                        },
                                    },
                                    {
                                        valueType: 'list',
                                        children: {
                                            title: '相关问题',
                                            children: [
                                                {
                                                    href: 'www.alipay.com',
                                                    title: '鹊凿平台DCI申领操作手册?',
                                                },
                                                {
                                                    href: 'www.alipay.com',
                                                    title: 'openAPI 注册工具?',
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ]}
                valueTypeMap={map}
            >
                <div>
                    <div onClick={() => setOpen(!open)} style={{cursor: "pointer"}}>HELP</div>
                    <ProHelpModal
                        modalProps={{
                            open: open,
                            afterClose (open) {
                                setOpen(open);
                            },
                        }}
                    />
                </div>
            </ProHelp>
        </div>
    );
}

const Header = ({menuStatusChange}) => {
    const [dropdown, setDropdown] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {userInfo} = useSelector(state => state.user);
    const [helpOPen, setHelpOpen] = useState(false);

    const logout = () => {
        doLogout(dispatch).then(_ => navigate("/login"))
    }

    return (
        <>
            <div className="header flex align-center gap-20">

                <MenuUnfoldOutlined
                    className="menu-icon"
                    onClick={() => {
                        menuStatusChange(true)
                    }}
                />
                <div className="flex clickable" onClick={() => navigate("/")}>
                    <img src={Content.logoUrl} alt="" height={32}/>
                    <div className="title">{Content.title}</div>
                </div>
                <div className="flex-1"></div>
                <Help/>
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: (
                                <div onClick={logout}>
                                    <LogoutOutlined/> Logout
                                </div>
                                // <Popconfirm
                                //     title="Confirm"
                                //     description="Are you sure to logout?"
                                //     icon={
                                //         <QuestionCircleOutlined
                                //             style={{
                                //                 color: 'green',
                                //             }}
                                //         />
                                //     }
                                //     onConfirm={logout}
                                //
                                // >
                                //     <LogoutOutlined/> Logout
                                // </Popconfirm>
                            ),
                        }
                    ]
                }}
                >
                    <div className="clickable flex align-center gap-10">
                        {
                            userInfo.nickName ?
                                <img
                                    src={"https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg"}
                                    alt="" height={32} className="round"/> :
                                null
                        }
                        <div>{userInfo.nickName}</div>
                    </div>
                </Dropdown>
            </div>
        </>
    )
}

export default Header;
