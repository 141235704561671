import React, {useEffect, useState} from 'react';
import {Table, Switch, Tag, Rate, Input  } from 'antd';
import {useRequest} from "ahooks";
import {getFeedbackList, getTaskList, getTotalTasks} from "../../config/api";



const Feedback = () => {
    const [taskList, setTaskList] = useState([])
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const {runAsync, loading} = useRequest(getFeedbackList, {manual: true})
    const handleTaskList = (r) => {
        return r?.map(feedback => {
            return {key: feedback.id, ...feedback}
        })
    }
    useEffect(() => {
        runAsync({current: 0, pageSize: 10}).then(r => {
            setTaskList(handleTaskList(r));
            setTotal(r.length);
        }
        ).catch()
    }, [])
    const changePageMessage = (page, newPageSize) => {
        if (pageSize !== newPageSize) {
            if (newPageSize <= pageSize) {
                setTaskList(taskList.slice(0, newPageSize))
                setPageSize(newPageSize);
                return
            }
        }
        setPageSize(newPageSize);
        runAsync({current: page - 1, pageSize: newPageSize}).then(r => {
            setTaskList(handleTaskList(r))
            setTotal(r.length);
        })
        // }
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 80
        },
        {
            title: 'DateTime',
            dataIndex: 'dateTime',
            key: 'dateTime',
            width: 60
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
            width: 60,
            render: (rate) => <Rate  key={rate} disabled defaultValue={rate} allowHalf />,
        },{
            title: 'Comment',
            dataIndex: 'text',
            key: 'text',
            width: 80,
            render: (text) => <div style={{maxHeight: 68, overflowY: "auto"}}>{text}</div>,
        }
    ];
    return <div className="card">
        <Table
            columns={columns}
            scroll={{x: 800}}
            pagination={{
                onChange: changePageMessage,
                // position: ["bottomCenter"],
                pageSize: pageSize,
                total: total
            }}
            dataSource={taskList}
            size="small"
            tableLayout="fixed"
            loading={loading}
        />
    </div>
}

export default Feedback;