import React from 'react';
import Content from "../../../config/content";

const Footer = () => {

    const goToGithub = () => {
        window.open("https://github.com/XiaoBaiOvO")
    }

    const goToICP = () => {
        window.open("https://beian.miit.gov.cn/")
    }

    return (
        <div className="footer">
            <div>Copyright © {Content.company}</div>
            <div>Designed By
                <span className="author-text" onClick={goToGithub}>
                    Ethan Hong
                </span>
            </div>
            <div className="icp-text" onClick={goToICP}>
                {Content.icp}
            </div>
        </div>
    )
}

export default Footer;