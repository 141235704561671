import React, {useEffect, useState} from "react";
import {Button, Card, Col, DatePicker, Row, Statistic} from "antd";
import {ProCard, StatisticCard} from "@ant-design/pro-components";
// import {getTestData, getV1Data} from "../../config/api";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {useRequest} from "ahooks";
import Freight from "./freight";


dayjs.extend(customParseFormat);

const dateFormat = 'YYYY-MM-DD';

function Home() {

    const [date, setDate] = useState(new Date())
    const [data, setData] = useState({})
    // const {runAsync: doRequest, loading: requestLoading} = useRequest(getTestData, {manual: true});

    useEffect(() => {
        setInterval(() => {
            setDate(new Date())
        }, 1000)
        getData(new Date(dayjs('2022-12-31', dateFormat)))
    }, [])

    const getData = (date) => {
        // doRequest({date}).then(r => {
        //     setData(r)
        // })
    }

    return (
        <div className="page-container">
            {/*<ProCard>*/}
            {/*    <DatePicker*/}
            {/*        defaultValue={dayjs('2022-12-31', dateFormat)}*/}
            {/*        onChange={(e) => {*/}
            {/*            getData(new Date(e))*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</ProCard>*/}
            {/*<Button type="primary" loading={false}>Start</Button>*/}
            {/*<ProCard loading={requestLoading} title={data?.date + "AD数据一览"}>*/}
            {/*    <Row>*/}
            {/*        <Col span={4}>*/}
            {/*            <Statistic title="总曝光量" value={data?.totalImpression} />*/}
            {/*        </Col>*/}
            {/*        <Col span={4}>*/}
            {/*            <Statistic title="广告费总和" value={data?.totalAdCost} />*/}
            {/*        </Col>*/}
            {/*        <Col span={4}>*/}
            {/*            <Statistic title="点击总和" value={data?.totalClick} />*/}
            {/*        </Col>*/}
            {/*        <Col span={4}>*/}
            {/*            <Statistic title="点击率" value={(data?.totalClick / data?.totalImpression * 100).toFixed(3)+ "%"} />*/}
            {/*        </Col>*/}
            {/*        <Col span={4}>*/}
            {/*            <Statistic title="广告销量" value={data?.totalAdOrder} />*/}
            {/*        </Col>*/}
            {/*        <Col span={4}>*/}
            {/*            <Statistic title="转换率" value={(data?.totalAdOrder / data?.totalClick * 100).toFixed(3)+ "%"} />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</ProCard>*/}

            <ProCard>
                <ProCard title="物流价格计算器">
                    <Freight/>
                </ProCard>
            </ProCard>
        </div>
    );
}

export default Home;
