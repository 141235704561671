import React, {useEffect, useState} from "react";
import {Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Statistic} from "antd";
import {ProCard, StatisticCard} from "@ant-design/pro-components";
import {getTestData, getV1Data} from "../../config/api";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {useRequest} from "ahooks";

dayjs.extend(customParseFormat);

const ShippingCostCalculator = () => {

    const [cost, setCost] = useState(0);

    const calculateShippingCost = (values) => {
        const baseCost = 26;
        const costPerKg = 5;
        const weightLimit = 5;
        const sizeLimit = 80;
        const operateCost = 2.0

        const weight = values.weight
        const length = values.length
        const width = values.width
        const height = values.height

        const totalSize = parseFloat(length) + parseFloat(width) + parseFloat(height);

        let cost1 = baseCost + parseFloat(weight) * costPerKg +operateCost;
        let cost2 = baseCost + parseFloat(weight) * costPerKg +operateCost;

        if (weight > weightLimit && weight < 10) {
            cost1 += 6;
        }
        else if (weight >= 10 && weight < 15) {
            cost1 += 14;
        }
        else if (weight >= 15 && weight < 20) {
            cost1 += 16;
        }
        else if (weight >= 20 && weight < 25) {
            cost1 += 70;
        }
        else if (weight >= 25 && weight < 30) {
            cost1 += 85;
        }
        else if (weight >= 30 && weight < 40) {
            cost1 += 100;
        }
        else if (weight >= 30 && weight < 40) {
            cost1 += 100;
        }


        if (totalSize > sizeLimit && totalSize < 100) {
            cost2 += 3;
        }
        else if (totalSize >= 100 && totalSize < 120) {
            cost2 += 6;
        }
        else if (totalSize >= 120 && totalSize < 140) {
            cost2 += 14;
        }
        else if (totalSize >= 140 && totalSize < 150) {
            cost2 += 40;
        }
        else if (totalSize >= 150 && totalSize < 180) {
            cost2 += 55;
        }
        else if (totalSize >= 180 && totalSize < 200) {
            cost2 += 85;
        }
        else if (totalSize >= 200 && totalSize < 220) {
            cost2 += 100;
        }
        else if (totalSize >= 220 && totalSize < 240) {
            cost2 += 150;
        }
        else if (totalSize >= 240 && totalSize < 260) {
            cost2 += 200;
        }
        else if (totalSize >= 260 && totalSize < 280) {
            cost2 += 240;
        }
        setCost(Math.max(cost1, cost2));
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        calculateShippingCost(values)
    };

    return (
        <div>

            <Form onFinish={onFinish}>
                <Form.Item
                    label="Weight"
                    name="weight"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your weight!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Length"
                    name="length"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Length!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Width"
                    name="width"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Width!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Height"
                    name="height"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Height!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button block type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>

            <p>The shipping cost is: {cost} </p>
        </div>
    );
};

export default ShippingCostCalculator;
