import {Chart} from '@antv/g2';
import {Input, Button, Modal, Select, Pagination, Spin, Tag, message, Empty} from 'antd';
import {useEffect, useState} from "react";
import axios from "axios";
import './index.css'
import {useRequest} from "ahooks";
import {CheckCircleOutlined, DeleteOutlined, PlusCircleOutlined, SyncOutlined} from "@ant-design/icons";
import {useLocation} from "react-router-dom";


const cardRequest = async (url, body) => {
    return (await axios.post("https://aiviz.cn/api/card/" + url, body,
    // return (await axios.post("http://localhost:9031/api/card/" + url, body,
        {
            headers: {
                // "X-Access-Token": token
            }
        })).data.object
}

const cardPlayerList = async body => cardRequest("playerList", body)
const cardListQuery = async body => cardRequest("list/query", body)
const cardTableQuery = async body => cardRequest("table/test", body)

const cardDist = [
    "银折 RC",
    "银折 RC PSA10",
    "国宝 RPA /99"
]

const DataGraph = () => {
    const [data, setData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [playerList, setPlayerList] = useState([]);
    const [cardSearchList, setCardSearchList] = useState([]);
    const [cardSelectList, setCardSelectList] = useState([]);
    const [popList, setPopList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [displayPlayerList, setDisplayPlayerList] = useState([])
    const [pageDisplayPlayerList, setPageDisplayPlayerList] = useState([])
    const [inputModalShow, setInputModalShow] = useState(false)
    const [cardSearchQuery, setCardSearchQuery] = useState("")
    const {runAsync: doCardTableQuery, loading: cardTableQueryLoading} = useRequest(cardTableQuery, {manual: true});
    const {runAsync: doCardListQuery, loading: cardListQueryLoading} = useRequest(cardListQuery, {manual: true});
    const location = useLocation();


    useEffect(() => {
        let queryMessage = location.search.slice(1)
        console.log(queryMessage)
        let arr1 = queryMessage.split(",")
        const cardList = []
        arr1.forEach(item => {
            let items = item.split("|")
            cardList.push({player: items[0].replaceAll("%20", " "),cardType: cardDist[items[1]]})
        })
        setCardSelectList(cardList)
        console.log(cardList)
        requestTableData(cardList)
        cardPlayerList({}).then(r => {
            const {playerList, popList} = r
            setPlayerList(playerList)
            setPopList(popList)

            let cardList = []
            playerList.map(item => {
                cardList.push({player: item, cardType: "银折 RC"})
                cardList.push({player: item, cardType: "银折 RC PSA10"})
                cardList.push({player: item, cardType: "国宝 RPA /99"})
            })
            setCardSearchList(cardList)
        })
    }, [])
    const requestTableData = (cardList) => {
        doCardTableQuery({cardList: cardList ? cardList : cardSelectList}).then(r => {
            let tableData = r?.tableData
            tableData.sort((a, b) => {
                let dateA = new Date(a.date);
                let dateB = new Date(b.date);
                return dateA - dateB; // 升序排序
                // return dateB - dateA; // 降序排序
            });
            setData(tableData)
            const chart = new Chart({
                container: 'container',
                autoFit: true,
            });
            chart
                .data(tableData)
                .encode('x', 'date')
                .encode('y', 'price')
                .encode('color', 'player')
                .encode('shape', 'smooth')
                // .legend('color', {
                //     layout: {
                //         justifyContent: 'center',
                //         alignItems: 'flex-end',
                //         flexDirection: 'row',
                //     },
                // })
                .scale('y', {
                    type: 'linear',
                    domainMin: 0,
                    // domainMax: 4000
                });
            chart.axis('x', {title: false, size: 20, labelAutoHide: true}).axis('y', {title: '成交均价'});
            chart.line().encode('shape', 'smooth')
            chart.point().encode('shape', 'point').tooltip(false)
            chart.render();
        })
    }
    const pageChange = (page, pageSize) => {
        // console.log(page)
        // console.log(pageSize)
        setCurrentPage(page)
        setPageDisplayPlayerList(displayPlayerList.slice((page - 1) * 10, page * 10))
    }
    const search = () => {
        doCardListQuery({query: document.getElementById('searchInput').value}).then(r => {
            let data = []
            for (let i = 0; i < 100; i++) {
                data = [...data, ...r]
            }
            setDisplayPlayerList(data)
            setPageDisplayPlayerList(r.splice(0, 10))
        })
    }
    // const handleOk = () => {
    //     setIsModalOpen(false)
    //     // setPlayer(selectPlayer)
    //     // requestTableData(selectPlayer, selectCard)
    // }
    // const handleChange = (value) => {
    //     setSelectPlayer(value)
    // }
    // const handleTimeChange = (value) => {
    //     console.log(value)
    // }
    const Card = ({name, productUrl, imageUrl, price, currency, dateTime}) => {
        return <div style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            alignItems: "center",
            paddingBottom: 10,
            borderBottom: "1px solid #ccc"
        }}>
            {/*<img style={{width: "100px"}} src="https://10kv.oss-cn-shanghai.aliyuncs.com/image/78fbc0bb-d1df-4da9-a599-0bd0fddc2f51.jpg" alt=""/>*/}
            <img style={{width: "100px"}} src={imageUrl} alt=""/>
            <div style={{
                display: "flex",
                padding: "0 10px",
                flexDirection: "column",
                gap: 5,
                flex: 1,
            }}>
                <div>name: {name}</div>
                <div>currency: {currency}</div>
                <div>price: {price}</div>
                <div>dataTime: {dateTime}</div>
                {/*<div>productUrl: <a href={productUrl}>{productUrl}</a></div>*/}
            </div>
        </div>
    }
    return <>
        <Spin spinning={cardTableQueryLoading}>
            <div className="card">
                <div>
                    <div style={{display: "flex",gap: "10px"}}>
                        <div style={{position: "relative", flex: 1}}>
                            <Input
                                id="playerInput"
                                onFocus={() => setInputModalShow(true)}
                                placeholder="选择卡片"
                                onBlur={(event) => {
                                    console.log(event.relatedTarget)
                                    if (event.relatedTarget && event.relatedTarget.closest('.player-list-container')) {
                                        return;
                                    }
                                    setInputModalShow(false)
                                    requestTableData()
                                }}
                                onChange={(e) => {
                                    setCardSearchQuery(e.target.value)
                                }}></Input>
                            {
                                inputModalShow ?
                                    <div
                                        className="player-list-container"
                                        onClick={(event) => {
                                            document.getElementById('playerInput').focus()
                                            event.stopPropagation();
                                        }}
                                        style={{
                                            zIndex: 1000,
                                            position: "absolute",
                                            width: "100%",
                                            height: "400px",
                                            overflow: "auto",
                                            borderBottom: "1px solid #CCCCCC"
                                        }}>
                                        <div style={{
                                            border: "1px solid #ccc",
                                            borderTop: 0,
                                            display: "flex",
                                            flexDirection: "column",
                                            backgroundColor: "#ccc",
                                            gap: 1
                                        }}>
                                            {
                                                cardSearchList.map((item, index) => {
                                                    if (item.player.toLowerCase().replaceAll(" ", "").includes(cardSearchQuery.toLowerCase().replaceAll(" ", ""))) {
                                                        return (
                                                            <button key={index} style={{
                                                                backgroundColor: "#fff",
                                                                border: 0,
                                                                textAlign: "left"
                                                            }}>
                                                                <div style={{
                                                                    padding: 10,
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}
                                                                     onClick={() => {
                                                                         if (cardSelectList.includes(item)) {
                                                                             setCardSelectList(cardSelectList.filter(select => select !== item))
                                                                         } else {
                                                                             if (cardSelectList.length === 6) {
                                                                                 message.error("最多选6条").then()
                                                                                 return
                                                                             }
                                                                             setCardSelectList([...cardSelectList, item])
                                                                         }
                                                                     }}>
                                                                    <div style={{flex: 1}}>
                                                                        <div style={{
                                                                            fontSize: 16,
                                                                            fontWeight: 600
                                                                        }}>{item.player}</div>
                                                                        <div>{item.cardType}</div>
                                                                    </div>
                                                                    <div style={{
                                                                        fontSize: 20,
                                                                        color: cardSelectList.includes(item) ? "#597ef7" : "#888"
                                                                    }}>
                                                                        {cardSelectList.includes(item) ? <CheckCircleOutlined/> : <PlusCircleOutlined/>}
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div> : null
                            }
                        </div>
                        <Select
                            style={{
                                flex: 1,
                                maxWidth: "20%",
                                minWidth: 150
                            }}
                            // onChange={handleTimeChange}
                            options={[
                                {value: '最近7天', label: '最近7天'},
                                {value: '最近30天', label: '最近30天'},
                                {value: '最近90天', label: '最近90天'}
                            ]}
                            // value={selectPlayer}
                            defaultValue='最近90天'
                            showSearch={false}
                        />
                        {/*<SyncOutlined onClick={requestTableData}/>*/}
                    </div>
                    <div style={{display: "flex"}}>
                        {/*{*/}
                        {/*    selectPlayerList.map((item,index) => <Tag style={{fontSize: 13}} key={index} closable onClose={() => {*/}
                        {/*        const playerList = selectPlayerList.filter(item1 => item1 !== item)*/}
                        {/*        console.log(playerList)*/}
                        {/*        requestTableData(playerList,selectCard)*/}
                        {/*        setSelectPlayerList(playerList)*/}
                        {/*    }}>{item}</Tag>)*/}
                        {/*}*/}
                    </div>
                    {/*<Select*/}
                    {/*    // mode="multiple"*/}
                    {/*    tagRender={tagRender}*/}
                    {/*    style={{*/}
                    {/*        flex: 1*/}
                    {/*    }}*/}
                    {/*    placeholder="选择人物"*/}
                    {/*    onChange={handleChange}*/}
                    {/*    options={options}*/}
                    {/*    value={selectPlayer}*/}
                    {/*    showSearch={true}*/}
                    {/*    optionRender={(a, b) => {*/}
                    {/*        return <div>{a.key} +</div>*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
                <div style={{display: "flex", width: "100%", gap: "10px"}}>
                    {/*<Line key={chartKey} data={data} {...config} style={{flex: 1, minWidth: "400px"}}/>*/}
                    <div id="container" style={{flex: 1, height: 540, minWidth: "0px"}}>
                        {data.length === 0? <Empty style={{marginTop: 50}}/> : null}
                    </div>
                    <div style={{
                        display: window.innerWidth > 500 ? "flex" : "none",
                        flexDirection: "column",
                        gap: "10px",
                        width: "100%",
                        maxWidth: "20%",
                        minWidth: 150
                    }}>
                        {/*<div style={{*/}
                        {/*    fontSize: "14px",*/}
                        {/*    height: "32px",*/}
                        {/*    padding: "4px 15px",*/}
                        {/*    borderRadius: "6px",*/}
                        {/*    boxShadow: "0 2px 0 rgba(0, 0, 0, 0.02)",*/}
                        {/*    boxSizing: "border-box",*/}
                        {/*    textAlign: "center",*/}
                        {/*    backgroundColor: "#fff",*/}
                        {/*    letterSpacing: "1px",*/}
                        {/*    whiteSpace: "nowrap",*/}
                        {/*}}*/}
                        {/*>*/}
                        {/*</div>*/}
                        {/*/!*<Button onClick={() => setIsModalOpen(true)}>切换</Button>*!/*/}
                        {/*<div style={{height: "540px", display: "flex", flexDirection: "column", gap: "10px"}}>*/}
                        {/*    /!*{*!/*/}
                        {/*    /!*    cardList.map(item => {*!/*/}
                        {/*    /!*        return <Button style={{*!/*/}
                        {/*    /!*            color: selectCard === item.key ? "#85a5ff" : null,*!/*/}
                        {/*    /!*            borderColor: selectCard === item.key ? "#85a5ff" : null*!/*/}
                        {/*    /!*        }} key={item.key} onClick={() => {*!/*/}
                        {/*    /!*            setSelectCard(item.key)*!/*/}
                        {/*    /!*            requestTableData(player, item.key)*!/*/}
                        {/*    /!*        }}>{item.name}</Button>*!/*/}
                        {/*    /!*    })*!/*/}
                        {/*    /!*}*!/*/}
                        {/*</div>*/}
                        <div>已选择卡片</div>
                        {
                            cardSelectList.map((item, index) => {
                                return (
                                    <div key={index} style={{border: "1px solid #ccc", padding: 10, borderRadius: 10}}>
                                        <div style={{display: "flex"}}>
                                            <div style={{flex: 1}}>{item.player}</div>
                                            <DeleteOutlined onClick={() => {
                                                setCardSelectList(cardSelectList.filter(select => select !== item))
                                            }}/>
                                        </div>
                                        <div>{item.cardType}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </Spin>
        <Spin spinning={cardListQueryLoading}>
            <div className="card">
                <div style={{display: "flex", width: "100%", gap: "5px"}}>
                    <Input id="searchInput" placeholder="输入查询内容" onPressEnter={search}/>
                    <Button onClick={search}>查询</Button>
                </div>
                {
                    displayPlayerList.length !== 0 ?
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                gap: "10px",
                                padding: "10px"
                            }}>
                            {
                                pageDisplayPlayerList.map((item, index) => {
                                    return <Card {...item} key={index}/>
                                })
                            }
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <Pagination
                                    defaultCurrent={1}
                                    onChange={pageChange}
                                    current={currentPage} total={displayPlayerList.length}
                                    pageSize={10}
                                /></div>
                        </div> :
                        <div>
                            <div style={{padding: "0 20px"}}>
                                <ul style={{padding: "5px 5px 5px 5px", margin: 0}}>
                                    <li>To search for multiple variations of the same term enclose them in brackets and
                                        separate them by a comma.<br/><i>(2019-20, 19-20)</i> or <i>(PSA, BGS)</i></li>
                                    <li>Use a minus "-" sign to exclude terms from the search.<br/><i>(2020-21, 20-21)
                                        Lamelo Ball -box -case -break</i></li>
                                    <li>Use <i>&amp;</i> to match only terms of that pattern.<br/><i>Charizard
                                        PSA&amp;10</i></li>
                                </ul>
                            </div>
                        </div>
                }
            </div>
        </Spin>
        {/*<Modal title="选择人物卡片" open={isModalOpen} onOk={handleOk} onCancel={() => setIsModalOpen(false)}>*/}
        {/*    <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>*/}
        {/*        <div style={{display: "flex"}}>*/}
        {/*            <div>人物名：</div>*/}
        {/*            <Select*/}
        {/*                style={{*/}
        {/*                    flex: 1*/}
        {/*                }}*/}
        {/*                placeholder="选择人物"*/}
        {/*                // onChange={handleChange}*/}
        {/*                // value={selectPlayer}*/}
        {/*                showSearch={true}*/}
        {/*            />*/}
        {/*        </div>*/}
        {/*        <div style={{display: "flex"}}>*/}
        {/*            <div>热门：</div>*/}
        {/*            <div style={{display: "flex", flexWrap: "wrap", gap: "10px", flex: 1, color: "black"}}>*/}
        {/*                {*/}
        {/*                    popList.map((item, index) => {*/}
        {/*                        return <div*/}
        {/*                            className="text-button"*/}
        {/*                            style={{*/}
        {/*                                padding: "6px 18px",*/}
        {/*                                borderRadius: "18px",*/}
        {/*                                border: '1px solid #EAEAEA'*/}
        {/*                            }} key={index}*/}
        {/*                            // onClick={() => setSelectPlayer(item)}*/}
        {/*                        >*/}
        {/*                            {item}*/}
        {/*                        </div>*/}
        {/*                    })*/}
        {/*                }*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</Modal>*/}
    </>
}
export default DataGraph