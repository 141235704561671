import {request} from "./request";

export const getTotalTasks = async body => request("aiviz/digital", body)
export const getUsageDetail = async body => request("aiviz/usage", body)
export const getPopularRank = async body => request("aiviz/popular", body)
export const getTaskList = async body => request("aiviz/taskList",body)
export const generate = async body => request("aiviz/playground/generate/v1",body)
export const getPrompt = async body => request("aiviz/playground/prompt",body)
export const getFeedbackList = async body => request("aiviz/feedback/list",body)
export const changeTaskState = async body => request("aiviz/taskList/active",body)
export const optionList = async body => request("aiviz/optionList/v2",body)
export const updateOptionList = async body => request("aiviz/optionList/update",body)
export const rollback = async body => request("aiviz/optionList/rollback",body)
export const playgroundPromptUpdate = async body => request("aiviz/playground/prompt/update",body)
export const getUpdateLog = async body => request("aiviz/updateLog",body)
export const updateUpdateLog = async body => request("aiviz/updateLog/update",body)
