import {useEffect, useState} from "react";
import {optionList, rollback, updateOptionList} from "../../config/api";
import {Button, Divider, Input, message} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
export default () => {
    const [data, setData] = useState({})
    const [reLoad, setReLoad] = useState([])
    const [active, setActive] = useState("")
    useEffect(() => {
        optionList().then(r => {
            setData(r)
            setActive(active === "" ? Object.keys(r)?.[0] : active)
        })
    }, [])
    const onChange1 = (index1) => (e) => {
        let newData = data
        newData[active][index1].key = e.target.value
        setData(newData)
        setReLoad([])
    }

    const onChange2 = (index1) => (e) => {
        let newData = data
        newData[active][index1].prompt = e.target.value
        setData(newData)
        setReLoad([])
    }

    const onChange3 = (index1, index2, type) => (e) => {
        let newData = data
        newData[active][index1].list[index2][type === 0 ? "key" : (type === 1 ? "prompt" : "alias")] = e.target.value
        setData(newData)
        setReLoad([])
    }
    const updateOption = () => {
        const handleData2 = (data) => {
            let newData = {}
            for (let key in data){
                newData[key] = data[key].map((item, index) => {
                    if (item.list) {
                        return {
                            label: item.key, value: index,
                            list: item.list.map((item2,index2) => {
                                return key === "designer" ? {label: item2.key, value: index2, alias: item2.alias} : {label: item2.key,value: index2}
                            })
                        }
                    } else return {label: item.key, value: index, alias: item.alias}
                })
            }
            return newData
        }
        const handleData3 = (data) => {
            let newData = {}
            for (let key in data) {
                newData[key] = {}
                data[key].forEach(item => {
                    if (item.list) {
                        item.list.forEach(item => {
                            newData[key][item.key] = item.prompt
                        })
                    }
                    newData[key][item.key] = item.prompt
                })
            }
            return newData
        }
        updateOptionList({object1: data, object2: handleData2(data), object3: handleData3(data)}).then(r => {
            message.success("更改成功").then()
            setData(r)
        })
    }
    const listAdd = (item,active) => {
        // console.log(data,active)
        // console.log(item)
        const newData = data
        const index = data[active].indexOf(item)
        if (active === "designer") {
            newData[active][index].list.push({key: '', prompt: '', alias: ""})
        } else {
            newData[active][index].list.push({key: '', prompt: ''})
        }
        setData(newData)
        setReLoad([])
        // console.log(newData[active][index].list)
    }
    const listDelete = (item,active,index2) => {
        const newData = data
        const index = data[active].indexOf(item)
        newData[active][index].list.splice(index2 ,1)
        if (newData[active][index].list.length === 0) {
            newData[active].splice(index,1)
        }
        setData(newData)
        setReLoad([])
    }
    const listDelete2 = (index) => {
        const newData = data
        newData[active].splice(index,1)
        setData(newData)
        setReLoad([])
    }
    const rollbackList = () => {
    //     setData([])
    //     rollback().then(r => {
    //         setData(r)
    //     })
    }
    const addItem = () => {
        const newData = data
        if (data[active][0]?.list) {
            newData[active].push({key: "", list: [{key: "", prompt: ""}]})
        } else {
            newData[active].push({key: "", prompt: ""})
        }
        setData(newData)
        setReLoad([])
    }
    return (
        <>
            <div style={{display: "flex", gap: 20, flexWrap: "wrap"}}>
                <div className="card" style={{maxWidth: 250, flex: 1}}>
                    {
                        Object.keys(data).map((item,index) => {
                            return (
                                <div className={"div-button " + (item === active ? "div-button-active" : "")} key={index}
                                    onClick={() => setActive(item)}>{item}</div>
                            )
                        })
                    }
                    {/*侧边栏按钮*/}
                    {/*<div style={{padding: 5, borderRadius: 10, border: "1px dashed #aaa", textAlign: "center"}}>Add*/}
                    {/*</div>*/}
                </div>
                <div className="card" style={{flex: 1}}>
                    <div>{active}</div>
                    <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                        {data?.[active]?.map((item, index1) => (
                            <div style={{display: "flex", gap: 20}} key={index1}>
                                <div style={{flex: 1}}>
                                    <Input value={item.key} onChange={onChange1(index1)}/>
                                </div>
                                {
                                    item.prompt || item.prompt === "" ?
                                        <><div style={{flex: 1}}><Input value={item.prompt}
                                            onChange={onChange2(index1)}/></div>
                                        <div><DeleteOutlined onClick={() => listDelete2(index1)}/></div>
                                        </>
                                        :
                                        <div style={{flex: 3, display: "flex", flexDirection: "column", gap: 10}}>
                                            {
                                                item.list?.map((item2, index2) => (
                                                    <div style={{display: "flex", gap: 10}} key={index2}>
                                                        <div style={{flex: 1}}><Input
                                                            value={item2.key}
                                                            onChange={onChange3(index1, index2, 0)}/>
                                                        </div>
                                                        {item2.alias || item2.alias === "" ? <div style={{flex: 1}}><Input
                                                            value={item2.alias}
                                                            onChange={onChange3(index1, index2, 2)}/>
                                                        </div> : null}
                                                        <div style={{flex: 1}}><Input
                                                            value={item2.prompt}
                                                            onChange={onChange3(index1, index2, 1)}/>
                                                        </div>
                                                        <DeleteOutlined   onClick={() => listDelete(item,active,index2)}/>
                                                    </div>
                                                ))
                                            }
                                            <div style={{
                                                padding: 5,
                                                borderRadius: 10,
                                                border: "1px dashed #aaa",
                                                textAlign: "center",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => listAdd(item,active)}
                                            >Add
                                            </div>
                                        </div>
                                }
                            </div>
                        ))}
                    </div>
                    <div style={{
                        padding: 5,
                        borderRadius: 10,
                        border: "1px dashed #aaa",
                        textAlign: "center",
                        cursor: "pointer"
                    }}
                    onClick={addItem}
                    >Add
                    </div>
                </div>
                {/*底部按钮*/}
                <div style={{position: "fixed", bottom: 0, backgroundColor: "#fff",borderTop:" 1px solid #b0b0b0",height: 80, width: "100%",maxWidth: 1516, display: "flex", justifyContent: "center", flexWrap: "wrap", alignItems: "center", gap: 20}}>
                    <Button style={{ maxWidth: 300}} onClick={() => {
                        setData([])
                        optionList().then(r => {
                            setData(r)
                            // setActive(active === "" ? Object.keys(r)?.[0] : active)
                        })
                    }}>Load Online Prompt</Button>
                    <Button style={{ maxWidth: 300}} type="primary" ghost onChange={() => {
                        localStorage.setItem("options",data)
                    }}>Local Temporary Storage</Button>
                    <Button style={{ maxWidth: 300}} onClick={updateOption} type="primary" danger>Deploy To Online</Button>
                    <Button style={{ maxWidth: 300}} onClick={rollbackList} type="primary" >Rollback</Button>
                </div>
            </div>
        </>
    )

}