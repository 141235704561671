import {Col, Row, Statistic} from "antd";
import React, {useEffect, useState} from "react";
import CountUp from 'react-countup'
import {getTotalTasks} from "../../config/api";
import {useRequest} from "ahooks";

const formatter = (value) =>  <CountUp end={value} separator=","/>;
const DigitalCard = () => {

    const [data, setData] = useState({todayTaskCount:0,todayUserCount:0,averageTimeCost:0,totalTaskCount:0})
    const {runAsync, loading} = useRequest(getTotalTasks, {manual: true});

    useEffect(() => {
        runAsync().then(r => {
            setData(r)
        })
    }, [])

    return (
        <div className="card">
            <div className="title-graph">Digital</div>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <div style={{display: "flex", flex:1, minWidth: 250}}>
                    <Statistic style={{flex: 1}} title="Task Count" value={data?.todayTaskCount} formatter={formatter}/>
                    <Statistic style={{flex: 1}} title="ACtive User" value={data?.todayUserCount} formatter={formatter}/>
                </div>
                <div style={{display: "flex", flex:1, minWidth: 250}}>
                    <Statistic style={{flex: 1}} title="Average Time Cost" value={data?.averageTimeCost / 1000} formatter={(value) => <CountUp end={value} separator="," decimals={3}/>}/>
                    <Statistic style={{flex: 1}} title="Total Task Count" value={data?.totalTaskCount } formatter={formatter}/>
                </div>
            </div>
        </div>
    )

}
export default DigitalCard